export const tableHeadData = [
  "Basic Info",
  "Phone Number",
  "Email Address",
  "Address",
  "Specialty",
  "Status",
  "Action",
];

